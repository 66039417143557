import React from "react";
import TextAreaWithWordCount from "../../../../../components/BRSRComponets/TextAreaInputWithCount/TextAreaWithCount";
import { Tags } from "../../../../../components/Tags/Tags";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import UploadButton from "../../../../../components/Buttons/UplodBotton/UploadButton";
import EditableCell from "../../../../../components/BRSRComponets/EdiTableCell/EdiTableCell";
import EvidenceComponent from "../../../../../components/EvidenceButton";
import RemarkHistory from "../../../RemarkHistory/RemarkHistory";
import AddRowButton from "../../../../../components/Buttons/AddRowButton/AddRowButton";

const PrincipleFour = ({
  showQuestion,
  pageWiseValue,
  dispatch,
  setPageWiseValue,
  setPageWiseErrors,
  pageWiseErrors,
  activeListItem,
  // yearValue = "2024-2025",
  data
}) => {
  const yearValue = data.reportFyYear
  let allData = JSON.parse(JSON.stringify(pageWiseValue));
  let allErrors = JSON.parse(JSON.stringify(pageWiseErrors));

  const lastYear = parseInt(yearValue.split("-")[0]);
  const currentYear = parseInt(yearValue.split("-")[1]);
  const prevFinancialYear = `${lastYear - 1}-${currentYear - 1}`;

  const handleTextAreaChange = (e, index, field, newValue) => {
    allData.SectionC.principleFour[e.target.name] = e.target.value;
    dispatch(setPageWiseValue(allData));
  };

  const handleCapexChange = (rowIndex, field, value, e) => {
    allData.SectionC.principleFour.capitalExpenditure[rowIndex][field] = value;
    dispatch(setPageWiseValue(allData));
  };

  const addNewRowCapexAssessment = () => {
    let productRowObj = {
      stakeHolderGroup: "",
      vulnerable: "",
      channelsOfCommunication: "",
      frequencyOfEngangement: "",
      purposeOfEngangement: ""
    };

    allData.SectionC.principleFour.capitalExpenditure.push(productRowObj);

    dispatch(setPageWiseValue(allData));
  };

  const deleteRow = (rowIndex, name) => {
    if (rowIndex > 0) {
      let tempArray = [...allData.SectionC.principleFour[name]];
      tempArray.splice(rowIndex, 1);
      allData.SectionC.principleFour[name] = tempArray;
      dispatch(setPageWiseValue(allData));
    }
  };

  const addNewRowCapexExp = () => {
    let capexObj = {
      stakeHolderGroup: "",
      vulnerable: "",
      channelsOfCommunication: "",
      frequencyOfEngangement: "",
      purposeOfEngangement: ""
    };

    allData.SectionC.principleFour.capitalExpenditure.push(capexObj);

    dispatch(setPageWiseValue(allData));
  }

  return (
    <>
      {showQuestion.some((obj) => Object.values(obj).includes(80)) && (
        <div className="d-flex flex-column gap-3 mb-4 mt-4">
          <div className="d-flex gap-2">
            {showQuestion
              .find((obj) => Object.values(obj).includes(80))
              ?.esgTags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#E3F2FD"} border={"#D6ECFC"} tagName={tag} />
              ))}
            {showQuestion
              .find((obj) => Object.values(obj).includes(80))
              ?.tags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#FFF9E5"} border={"#FCF1CC"} tagName={tag} />
              ))}
          </div>
          <div className="d-flex gap-1">
            <p className="question">
              1. Describe the processes for identifying key stakeholder groups of the entity.
              <img src="/images/icons/QestionShape.svg" alt="" />
            </p>
          </div>
          <div style={{ marginTop: "-14px" }}>
            {showQuestion.map((obj, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.values(obj).includes(80) &&
                  (obj.contributors[0].isRejected ||
                    obj.contributors[0].isAdminRejected ||
                    obj.contributors[0].isApproved ? (
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="caption-1-Regular m-0">Status</p>
                      </div>
                      <div>
                        <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
                          {obj.contributors[0].isRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">Rejected</p>
                            </div>
                          ) : obj.contributors[0].isAdminRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">
                                Rejected by <span className="caption-1-Regular">Admin</span>
                              </p>
                            </div>
                          ) : obj.contributors[0].isApproved ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                                  fill="#38CB24"
                                />
                              </svg>
                              <p className="caption-1-Emphasized">Approved</p>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ))}
          </div>
          <div className="brsr-input">
            <TextAreaWithWordCount
              placeholder="Write here"
              maxCharacters={500}
              name="processOfIdentification"
              value={allData?.SectionC?.principleFour?.processOfIdentification}
              onChange={handleTextAreaChange}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <EvidenceComponent
              data={data}
              allData={allData}
              dispatch={dispatch}
              setPageWiseValue={setPageWiseValue}
              name="SectionC.principleFourQuestionOneEvidence"
            />
            <RemarkHistory
              data={data}
              questionId={Object.keys(showQuestion.find((obj) => Object.values(obj).includes(80)))[0]}
            />
          </div>
        </div>
      )}

      {showQuestion.some((obj) => Object.values(obj).includes(81)) && (
        <div className="d-flex flex-column gap-3 mb-4 mt-4">
          <div className="d-flex gap-2">
            {showQuestion
              .find((obj) => Object.values(obj).includes(81))
              ?.esgTags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#E3F2FD"} border={"#D6ECFC"} tagName={tag} />
              ))}
            {showQuestion
              .find((obj) => Object.values(obj).includes(81))
              ?.tags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#FFF9E5"} border={"#FCF1CC"} tagName={tag} />
              ))}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p className="question">
              2. List stakeholder groups identified as key for your entity and the frequency of engagement with each
              stakeholder group.
            </p>
          </div>
          <div style={{ marginTop: "-14px" }}>
            {showQuestion.map((obj, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.values(obj).includes(81) &&
                  (obj.contributors[0].isRejected ||
                    obj.contributors[0].isAdminRejected ||
                    obj.contributors[0].isApproved ? (
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="caption-1-Regular m-0">Status</p>
                      </div>
                      <div>
                        <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
                          {obj.contributors[0].isRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">Rejected</p>
                            </div>
                          ) : obj.contributors[0].isAdminRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">
                                Rejected by <span className="caption-1-Regular">Admin</span>
                              </p>
                            </div>
                          ) : obj.contributors[0].isApproved ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                                  fill="#38CB24"
                                />
                              </svg>
                              <p className="caption-1-Emphasized">Approved</p>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ))}
          </div>
          <div className="brsr-table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Stakeholder Group</th>
                  <th scope="col">Whether identified as Vulnerable & Marginalized Group (Yes/No)</th>
                  <th scope="col">
                    Channels of communication (Email, SMS, Newspaper, Pamphlets, Advertisement, Community Meetings,
                    Notice Board, Website), Other
                  </th>
                  <th scope="col">
                    Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)
                  </th>
                  <th scope="col">
                    Purpose and scope of engagement including key topics and concerns raised during such engagement
                  </th>
                  <th className="xs-column" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {allData?.SectionC?.principleFour?.capitalExpenditure?.map((item, index) => (
                  <tr key={index + "disc"}>
                    <EditableCell
                      value={item?.stakeHolderGroup}
                      rowIndex={index}
                      field="stakeHolderGroup"
                      onValueChange={handleCapexChange}
                    />
                    <td className="horizontal-table-input">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group column"
                          name="vulnerable"
                          value={item?.vulnerable}
                          onChange={(e) => handleCapexChange(index, "vulnerable", e.target.value, e)}
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </td>
                    <EditableCell
                      value={item?.channelsOfCommunication}
                      rowIndex={index}
                      field="channelsOfCommunication"
                      onValueChange={handleCapexChange}
                    />
                    <EditableCell
                      value={item?.frequencyOfEngangement}
                      rowIndex={index}
                      field="frequencyOfEngangement"
                      onValueChange={handleCapexChange}
                    />
                    <EditableCell
                      value={item?.purposeOfEngangement}
                      rowIndex={index}
                      field="purposeOfEngangement"
                      onValueChange={handleCapexChange}
                    />
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/BRSR/brsr-delete.svg"
                          alt=""
                          onClick={() => deleteRow(index, "capitalExpenditure")}
                          className="brsr-no-style-input"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex aling-items-center">
            <div className="d-flex gap-2 align-items-center">
              <EvidenceComponent
                data={data}
                dispatch={dispatch}
                setPageWiseValue={setPageWiseValue}
                name="SectionC.principleFourQuestionTwoEvidence"
              />
              <RemarkHistory
                data={data}
                questionId={Object.keys(showQuestion.find((obj) => Object.values(obj).includes(81)))[0]}
              />

            </div>
            <AddRowButton
              buttonText={"+ Add a new row"}
              addRow={addNewRowCapexExp}
              customeClass="brsr-addRow-button"
            />
          </div>
        </div>
      )}

      {showQuestion.some((obj) => Object.values(obj).includes(82)) && (
        <div className="d-flex flex-column gap-3 mb-4 mt-4">
          <div className="d-flex gap-2">
            {showQuestion
              .find((obj) => Object.values(obj).includes(82))
              ?.esgTags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#E3F2FD"} border={"#D6ECFC"} tagName={tag} />
              ))}
            {showQuestion
              .find((obj) => Object.values(obj).includes(82))
              ?.tags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#FFF9E5"} border={"#FCF1CC"} tagName={tag} />
              ))}
          </div>
          <div className="d-flex gap-1">
            <p className="question">
              1. Provide the processes for consultation between stakeholders and the Board on economic, environmental,
              and social topics or if consultation is delegated, how is feedback from such consultations provided to the
              Board.
              <img src="/images/icons/QestionShape.svg" alt="" />
            </p>
          </div>
          <div style={{ marginTop: "-14px" }}>
            {showQuestion.map((obj, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.values(obj).includes(82) &&
                  (obj.contributors[0].isRejected ||
                    obj.contributors[0].isAdminRejected ||
                    obj.contributors[0].isApproved ? (
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="caption-1-Regular m-0">Status</p>
                      </div>
                      <div>
                        <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
                          {obj.contributors[0].isRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">Rejected</p>
                            </div>
                          ) : obj.contributors[0].isAdminRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">
                                Rejected by <span className="caption-1-Regular">Admin</span>
                              </p>
                            </div>
                          ) : obj.contributors[0].isApproved ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                                  fill="#38CB24"
                                />
                              </svg>
                              <p className="caption-1-Emphasized">Approved</p>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ))}
          </div>
          <div className="brsr-input">
            <TextAreaWithWordCount
              placeholder="Write here"
              maxCharacters={500}
              name="processOfConsultation"
              value={allData?.SectionC?.principleFour?.processOfConsultation}
              onChange={handleTextAreaChange}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <EvidenceComponent
              data={data}
              allData={allData}
              dispatch={dispatch}
              setPageWiseValue={setPageWiseValue}
              name="SectionC.principleFourLeadershipQuestionOneEvidence"
            />
            <RemarkHistory
              data={data}
              questionId={Object.keys(showQuestion.find((obj) => Object.values(obj).includes(82)))[0]}
            />
          </div>
        </div>
      )}
      {showQuestion.some((obj) => Object.values(obj).includes(83)) && (
        <div className="d-flex flex-column gap-3 mb-4 mt-4">
          <div className="d-flex gap-2">
            {showQuestion
              .find((obj) => Object.values(obj).includes(83))
              ?.esgTags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#E3F2FD"} border={"#D6ECFC"} tagName={tag} />
              ))}
            {showQuestion
              .find((obj) => Object.values(obj).includes(83))
              ?.tags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#FFF9E5"} border={"#FCF1CC"} tagName={tag} />
              ))}
          </div>
          <div className="d-flex gap-1">
            <p className="question">
              2. Whether stakeholder consultation is used to support the identification and management of environmental,
              and social topics (Yes / No). If so, provide details of instances as to how the inputs received from
              stakeholders on these topics were incorporated into policies and activities of the entity.
              <img src="/images/icons/QestionShape.svg" alt="" />
            </p>
          </div>
          <div style={{ marginTop: "-14px" }}>
            {showQuestion.map((obj, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.values(obj).includes(83) &&
                  (obj.contributors[0].isRejected ||
                    obj.contributors[0].isAdminRejected ||
                    obj.contributors[0].isApproved ? (
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="caption-1-Regular m-0">Status</p>
                      </div>
                      <div>
                        <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
                          {obj.contributors[0].isRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">Rejected</p>
                            </div>
                          ) : obj.contributors[0].isAdminRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">
                                Rejected by <span className="caption-1-Regular">Admin</span>
                              </p>
                            </div>
                          ) : obj.contributors[0].isApproved ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                                  fill="#38CB24"
                                />
                              </svg>
                              <p className="caption-1-Emphasized">Approved</p>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ))}
          </div>
          <div className="brsr-input">
            <TextAreaWithWordCount
              placeholder="Write here"
              maxCharacters={500}
              name="consultationForSupport"
              value={allData?.SectionC?.principleFour?.consultationForSupport}
              onChange={handleTextAreaChange}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <EvidenceComponent
              data={data}
              allData={allData}
              dispatch={dispatch}
              setPageWiseValue={setPageWiseValue}
              name="SectionC.principleFourLeadershipQuestionTwoEvidence"
            />
            <RemarkHistory
              data={data}
              questionId={Object.keys(showQuestion.find((obj) => Object.values(obj).includes(83)))[0]}
            />
          </div>
        </div>
      )}
      {showQuestion.some((obj) => Object.values(obj).includes(84)) && (
        <div className="d-flex flex-column gap-3 mb-4 mt-4">
          <div className="d-flex gap-2">
            {showQuestion
              .find((obj) => Object.values(obj).includes(84))
              ?.esgTags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#E3F2FD"} border={"#D6ECFC"} tagName={tag} />
              ))}
            {showQuestion
              .find((obj) => Object.values(obj).includes(84))
              ?.tags.filter(Boolean)
              .map((tag, index) => (
                <Tags key={index} bgColor={"#FFF9E5"} border={"#FCF1CC"} tagName={tag} />
              ))}
          </div>
          <div className="d-flex gap-1">
            <p className="question">
              3. Provide details of instances of engagement with, and actions taken to, address the concerns of
              vulnerable/ marginalized stakeholder groups.
              <img src="/images/icons/QestionShape.svg" alt="" />
            </p>
          </div>
          <div style={{ marginTop: "-14px" }}>
            {showQuestion.map((obj, index) => (
              <div key={index} className="d-flex flex-column">
                {Object.values(obj).includes(84) &&
                  (obj.contributors[0].isRejected ||
                    obj.contributors[0].isAdminRejected ||
                    obj.contributors[0].isApproved ? (
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="caption-1-Regular m-0">Status</p>
                      </div>
                      <div>
                        <p className="caption-1-Regular m-0" style={{ fontSize: "12px" }}>
                          {obj.contributors[0].isRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">Rejected</p>
                            </div>
                          ) : obj.contributors[0].isAdminRejected ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                              >
                                <circle opacity="0.1" cx="16" cy="16" r="12" fill="#FF535B" />
                                <circle opacity="0.8" cx="16.0003" cy="16" r="5.33333" fill="#FF535B" />
                              </svg>
                              <p className="caption-1-Emphasized">
                                Rejected by <span className="caption-1-Regular">Admin</span>
                              </p>
                            </div>
                          ) : obj.contributors[0].isApproved ? (
                            <div className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM13.0117 7.38638C13.3631 7.03491 13.3631 6.46506 13.0117 6.11359C12.6602 5.76211 12.0904 5.76211 11.7389 6.11359L7.87528 9.97719L6.26167 8.36359C5.9102 8.01211 5.34035 8.01211 4.98888 8.36359C4.63741 8.71506 4.63741 9.28491 4.98888 9.63638L7.23888 11.8864C7.40767 12.0552 7.63658 12.15 7.87528 12.15C8.11397 12.15 8.34289 12.0552 8.51167 11.8864L13.0117 7.38638Z"
                                  fill="#38CB24"
                                />
                              </svg>
                              <p className="caption-1-Emphasized">Approved</p>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ))}
          </div>
          <div className="brsr-input">
            <TextAreaWithWordCount
              placeholder="Write here"
              maxCharacters={500}
              name="instancesOfEngagement"
              value={allData?.SectionC?.principleFour?.instancesOfEngagement}
              onChange={handleTextAreaChange}
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <EvidenceComponent
              data={data}
              allData={allData}
              dispatch={dispatch}
              setPageWiseValue={setPageWiseValue}
              name="SectionC.principleFourLeadershipQuestionThreeEvidence"
            />
            <RemarkHistory
              data={data}
              questionId={Object.keys(showQuestion.find((obj) => Object.values(obj).includes(84)))[0]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PrincipleFour;
